<template>
  <footer class="main-footer sticky footer-type-1">
    <div class="footer-inner">
      <!-- 版权信息 -->
      <div class="footer-text">
        &copy; 2022
        <a href="#"><strong>OnO Tools</strong></a>
        supported by
        <a href="http://viggoz.com" target="_blank"
          ><strong>WebStack Viggo</strong></a
        >
      </div>

      <div class="go-up">
        <a href="#" rel="go-top"><i class="fa-angle-up"></i></a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterItem",
};
</script>

<style></style>
